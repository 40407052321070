import { getRandomInt } from "src/lib/utils";

const lorems = [
  `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Pretium nibh ipsum consequat nisl. Risus in hendrerit gravida rutrum quisque non tellus orci. Egestas congue quisque egestas diam in. Non odio euismod lacinia at quis. Vulputate odio ut enim blandit volutpat. Odio facilisis mauris sit amet massa vitae. Amet facilisis magna etiam tempor orci eu lobortis elementum. Ante metus dictum at tempor commodo ullamcorper. Adipiscing bibendum est ultricies integer quis auctor elit sed. Vitae elementum curabitur vitae nunc sed velit dignissim. Purus in massa tempor nec feugiat. Sociis natoque penatibus et magnis. Elit ut aliquam purus sit. Ornare quam viverra orci sagittis eu volutpat odio. Ante in nibh mauris cursus. Etiam non quam lacus suspendisse faucibus interdum posuere lorem. Porta nibh venenatis cras sed felis eget. Platea dictumst quisque sagittis purus. Mattis ullamcorper velit sed ullamcorper morbi tincidunt.`,
  `Pellentesque adipiscing commodo elit at imperdiet dui. Enim sed faucibus turpis in eu. Augue eget arcu dictum varius duis. Consectetur adipiscing elit duis tristique sollicitudin nibh sit amet commodo. Volutpat odio facilisis mauris sit amet massa vitae tortor. Vitae justo eget magna fermentum iaculis. In ante metus dictum at. Fringilla phasellus faucibus scelerisque eleifend. Sed euismod nisi porta lorem mollis. Tellus orci ac auctor augue mauris augue. Semper quis lectus nulla at volutpat diam ut. Rutrum tellus pellentesque eu tincidunt tortor aliquam nulla. Tristique risus nec feugiat in fermentum posuere urna. Nibh sit amet commodo nulla facilisi nullam. At tellus at urna condimentum mattis pellentesque. Est pellentesque elit ullamcorper dignissim cras tincidunt. Vestibulum lectus mauris ultrices eros in cursus turpis. A lacus vestibulum sed arcu non. Gravida arcu ac tortor dignissim convallis aenean et tortor at. Eget egestas purus viverra accumsan in nisl nisi scelerisque.`,
  `Pulvinar pellentesque habitant morbi tristique senectus et. Magna sit amet purus gravida quis blandit turpis cursus in. Consequat interdum varius sit amet mattis vulputate enim. Orci porta non pulvinar neque laoreet suspendisse. Dui accumsan sit amet nulla. Et odio pellentesque diam volutpat. Hac habitasse platea dictumst vestibulum rhoncus est pellentesque elit. Vel eros donec ac odio. Magna sit amet purus gravida quis blandit turpis. Adipiscing at in tellus integer. Suspendisse faucibus interdum posuere lorem ipsum dolor sit. Sodales neque sodales ut etiam sit amet nisl purus. Ipsum dolor sit amet consectetur. Odio pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Elit ut aliquam purus sit amet luctus venenatis lectus. Tristique senectus et netus et malesuada fames ac. Enim tortor at auctor urna. Sem fringilla ut morbi tincidunt augue. Sed turpis tincidunt id aliquet risus. Consectetur libero id faucibus nisl tincidunt eget.`,
  `Sed viverra tellus in hac habitasse platea dictumst. Semper auctor neque vitae tempus quam pellentesque nec. Interdum posuere lorem ipsum dolor. Habitasse platea dictumst quisque sagittis. Massa id neque aliquam vestibulum morbi blandit cursus risus at. Sed vulputate mi sit amet mauris commodo quis imperdiet. Aliquet risus feugiat in ante. Et magnis dis parturient montes nascetur ridiculus mus mauris. Cursus turpis massa tincidunt dui ut. Amet risus nullam eget felis eget nunc lobortis. Ultrices mi tempus imperdiet nulla malesuada. Sed enim ut sem viverra aliquet eget sit. Arcu cursus euismod quis viverra nibh cras. Ultrices eros in cursus turpis massa tincidunt dui ut ornare. Consectetur libero id faucibus nisl tincidunt eget nullam non.`,
  `Adipiscing elit ut aliquam purus sit amet luctus venenatis lectus. Eget felis eget nunc lobortis mattis. Tortor consequat id porta nibh venenatis cras sed felis. Arcu felis bibendum ut tristique et egestas quis ipsum. Ac orci phasellus egestas tellus rutrum tellus pellentesque eu tincidunt. Quisque sagittis purus sit amet volutpat consequat. Iaculis nunc sed augue lacus viverra vitae congue. Ut venenatis tellus in metus. Vestibulum morbi blandit cursus risus at ultrices mi. Aliquet sagittis id consectetur purus ut faucibus.`,
];

const loremIpsum = paragraphs => {
  const loremArr = [];

  const randomIncrementor = getRandomInt(5);

  for (var i = 0; i < paragraphs; i++) {
    let lorem = lorems[0];

    lorem = lorems[(i + randomIncrementor) % lorems.length];

    loremArr.push(lorem);
  }

  return loremArr;
};

export default loremIpsum;
