import React from "react";

import { Title, Text } from "src/components/page";
import { loremIpsum } from "src/lib/placeholder";

const Games = () => (
  <>
    <Title>Games</Title>

    {loremIpsum(3).map((paragraph, i) => (
      <Text key={paragraph + i}>{paragraph}</Text>
    ))}
  </>
);

export default Games;
